import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import {Container} from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import Home    from './pages/Home';
import Login   from './pages/Login';
import Task from './pages/Task';
import './App.css'; 
import React from 'react';
// import Source from './pages/Source';
import Forecast from './pages/Forecast';
import Autoline from './pages/Autoline';
// import Todo from './pages/Todo';
import Admin from './pages/Admin';
import Subscriber from './pages/Subscriber';
import Payment from './pages/Payment';
import Product from './pages/Product';
import Absent from './pages/Absent';
import Subscribe from './pages/Subscribe';
import 'core-js/features/array/at';
function App() {

  // let baseUrl = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?'/market':'/market';
  const baseUrl = '/market'; //static subdirectory
  return (

      <Router basename={baseUrl} >
        <Header />
        {/* <Header.Alert /> */}
        <main className = "py-sm-1 py-1 my-sm-1 my-1" style ={{height:'75vh'}}>
          <Container>
              <Routes>
                <Route path='login'  element ={<Login />} />
                <Route path='/' element = {<Home />} />
                <Route path='/admin' element = {<Admin />} />
                {/* <Route path = '/service' element = {<Todo />} />
                <Route path = '/service/:openID' element = {<Todo />} /> */}
                <Route path='/forecast' element = {<Forecast />} />
                <Route path='/product' element = {<Product />} />
                <Route path='/absent' element = {<Absent />} />
                <Route path='/nsk/:openID' element = {<p>nsk order entry</p>} />
                <Route path='/autoline' element = {<Autoline />} />
                <Route path='/subscribe/:ref' element = {<Subscribe />} />
                <Route path='/subscribe'      element = {<Subscribe />} />
                <Route path ='/task'    exact  element = { <Task />} />
                <Route path ='/task/:openID' element = { <Task />} />
                <Route path ='/payment' element = { <Payment />} />
              </Routes>
          </Container>
        </main>
        <Footer />
      </Router>
   
  );
}
export default App;
