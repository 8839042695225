import React from 'react';
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Row } from "react-bootstrap";
import { useAsyncDebounce } from "react-table";
import CreateDrawing from '../modals/CreateDrawing';
//bring the global Filter@20230608
const GlobalFilter=({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    language
  }) =>{
    const {value:inputValue} = useSelector(state=>state.input) || '';
    const {userInfo} = useSelector(state=>state.userLogin);
    const {pathname} = useLocation();

    const [value, setValue] = useState(globalFilter);

    const [show,setShow]    = useState(false);

    const handleShow = ()=>setShow(!show);

    const onChange = useAsyncDebounce(value => {
      //pattern checking of the value
      // console.log('rows',preGlobalFilteredRows)
      //if it is 
      setGlobalFilter(value || undefined)
    }, 200);

    React.useEffect(()=>{
       if(!inputValue) return;
       setValue(inputValue);
       onChange(inputValue);
    },[inputValue,onChange]);

  
    return (
        <Row className='text-center p-1' style = {{minHeight:'10vh'}}>
                <div className = {`form-group mx-auto ${/admin/.test(pathname)? 'search-input':''}`}>
                    <label className='float-left mb-3'>{ language==='en' ?'reference' :'业务编号'} <sup>*</sup>
                        
                       {userInfo?.open_id && ['oV9Cut8y8vzP3DzlLZWIJ8RNfdn8'].includes(userInfo?.open_id) && (
                           <>
                            {/* {show && (<CreateDrawing handle = {handleShow} />)}
                            <span 
                                  onClick = {handleShow}
                            >
                                  <span className ='btn btn-sm btn-outline-danger rounded ml-4'><i className = 'fa fa-cloud-upload' aria-hidden="true"></i></span>
                            </span> */}
                          </>
                      
                       )}
                    
                    </label>
                    <div className ='input-group'>
                        <input
                          className='form-control  mb-4 text-danger rounded'
                          style ={{minWidth:'300px',border:'1px solid #C4261D',padding:'10px 15px'}}
                          value={value || ""}
                          onChange={e => {
                            setValue(e.target.value.toUpperCase());
                            onChange(e.target.value.toUpperCase());
                          }}
                          placeholder={language==='en'?'typing...':'录入...'}
                          // disabled ={!show}
                        />
                
                    </div>
                   
                </div>
        </Row>
    )
  }
  export default GlobalFilter;