import RenderPdf from "./renderPdf/RenderPdf";

//isReading todo
const Actions = ({inputRef})=>{

    return (
        <>
          <RenderPdf />

        </>
    );
}

export default Actions;