import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloseBtn from '../components/CloseBtn';
import Loader from '../components/Loader';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// import LanguageContext from '../contexts/contexts';
import Translate from '../components/Translate';
import Message from '../components/Message';

const ItemList = ()=>{
    // const {language} = React.useContext(LanguageContext); 
    const [business,setBusiness] = React.useState({});
    const [show,setShow] = React.useState(false);
    const [loading,setLoading] = React.useState(false);
    const [err,setErr] = React.useState('');

    const [token,setToken] = React.useState('');

    const list = [{name:'包装材料',partNumber:'',dimension:['length','width','height'],type:['托盘','纸箱'],warehouseID:['W10','W11','W16']}];

    const handleClose = ()=>{setShow(!show)}

    const pickHandler = (name)=>{
   
          const item = list.find(x=>x.name === name);

          if(!item) return alert('NOT FOUND');
          
          setBusiness({...item,token:''}); 
          
          setShow(!show);
    }

    const randomNumber = ()=> {
       const num =  Math.floor(Math.random()*(9999-1000+1) + 1000);
       setToken(num);
    }

    const changeHandler = (e)=>{
          setBusiness(
            {
                ...business,
                [e.target.name]:e.target.value
            }
          )
    }

    const submitHandler = (e)=>{
          setLoading(true);

          setErr(token === parseInt(business.token) ? 'loading' :'口令不匹配');

          setTimeout(()=>{
            setLoading(false);
            setErr('');
          },5000);
    }

    return (
        <>
            <div className ='btn-group ml-3'>
            {
               list.map((item,i)=>{
                   return (
                        <span 
                            key = {i}
                            className="btn btn-sm  btn-info rounded" 
                            name = {item.name}
                            onClick = {()=>pickHandler(item.name)}
                        >{item.name}</span>
                   );
               })
            }
            </div>
            <Modal show={show} onHide={handleClose} className='modal-template'>
                <Modal.Header>
                   <h4>{business.name}</h4>
                   <CloseBtn onClick ={handleClose} />
                </Modal.Header>
                <Modal.Body className='dwg-body'>

                     <div className ='mx-auto' style = {{maxWidth:'550px'}}>
                     {
                        Object.keys(business).map((k,i)=>{
                            switch(k)
                            {   
                                case 'partNumber':
                                    return (
                                        <div className ='form-group'>
                                            <label><Translate k= {k} /><sup>*</sup></label>
                                            <input 
                                                type ='text' 
                                                className='form-control eparts-input'
                                                name = {k}
                                                value = {business.k}
                                                onChange = {changeHandler}
                                            />
                                        </div>
                                    )
                                case 'dimension':
                                    return (
                                        <>
                                           {
                                               business[k].map((t,i)=>{
                                                    return (
                                                        <div className ='form-group' key = {i}>
                                                            <label><Translate k= {t} /><sup>*</sup></label>
                                                            <input 
                                                                type ='number'
                                                                name = {t}
                                                                className ='form-control eparts-input'
                                                                value = {business.t}
                                                                onChange = {changeHandler}
                                                            />
                                                        </div>
                                                    )
                                               })
                                          }
                                        </>
                                   )
                                    
                                case 'type':
                                    return (
                                       <div className ='form-group'>
                                           <label><Translate k= {k} /> <sup>*</sup></label>
                                           <select name = {`materialType`}
                                               className = 'form-control eparts-input'
                                               value = {business?.materialType}
                                               onChange = {changeHandler}
                                           >
                                               {
                                                  business[k].map((t,i)=>{
                                                     return (
                                                        <option key = {i}>
                                                              {t}
                                                        </option>
                                                     )
                                                  })
                                               }
                                              
                                           </select>
                                       </div>
                                    );
                                    case 'warehouseID':
                                        return (
                                           <div className ='form-group'>
                                               <label><Translate k= {k} /> <sup>*</sup></label>
                
                                               <select name = {`warehouseNumber`}
                                                   className = 'form-control eparts-input'
                                                   value = {business?.warehouseNumber}
                                                   onChange = {changeHandler}
                                               >
                                                   {
                                                      business[k].map((t,i)=>{
                                                         return (
                                                            <option key = {i}>
                                                                  {t}
                                                            </option>
                                                         )
                                                      })
                                                   }
                                                  
                                               </select>
                                           </div>
                                    );

                                case 'token':

                                   return (
                                    <div className='form-group mt-3'>
                                    <label
                                       onClick = {randomNumber}
                                    ><Translate k = {k} /> <sup>*</sup> <span className ='text-danger ml-3'>{token}</span></label>
                                    <div className='input-group'>
                                        <input type ='text' 
                                            name =  {k}
                                            className ='form-control eparts-input' 
                                            value = {business.k}
                                            onChange = {changeHandler}
                                        />
                                        <div className ='input-group-append'>
                                            <span 
                                                className ='btn btn-sm btn-outline-danger rounded'
                                                onClick = {submitHandler}
                                            >
                                                {loading ?(<Loader size ='15' />):(<i className ='fa fa-save'></i>)}
                                            </span>
                                        </div>
                                     
                                        
                                    </div>
                                    <div className ='mt-1'>
                                        {
                                        err && (
                                        <Message variant ='danger' className='mt-3'>
                                                {err}
                                        </Message>)
                                        }
                                    </div>
                                 
                                    </div>
                                 

                                   );
                                default:
                                    return null;
                            }    
                        })
                     }
         
                
                      
                     </div>
                   
                     {/* <pre>{JSON.stringify(business,null,2)}</pre> */}
                </Modal.Body>
            </Modal>
        </>
    );
}


const ShowDrawing = ({row,handle})=>{

    const [show,setShow] = React.useState(true);
    const [loading,setLoading] = React.useState(false);
    const [err,setErr]         = React.useState('');
    const [file,setFile] = React.useState('');
    
    const handleClose = ()=> {
        setShow(false);
        handle();
    }

    //submitHandler
    const submitHandler = async(e)=>{
         try {
            e.stopPropagation();

            setLoading(true);
            
            //http_request
            const config = {
              headers : {
               'Content-Type':'application/json'
               // Authorization: `Bearer ${ userInfo.token }`
              }
            };

            //EDIT => update the record
            const URL =process.env.NODE_ENV === 'production'
            ?'https://service.eparts.cn/api/stamp'
            :'http://localhost:4000/api/stamp'; 


            const {data:{file,msg}}  = await axios.post(URL,{...row,base64:row.file},config);
            // console.log('response',data);
            setLoading(false);

            if(/ERROR/.test(msg)){
               throw new Error(msg);
            }

            setFile(file);
            // console.log(file);

         } catch (error){
            setErr(error.message.trim());
            setTimeout(()=>{
                setErr('');
            },5000);
            // console.log(error.message);
         }
    }

    return (
     <Modal show={show} onHide={handleClose} className='modal-template'>
        <Modal.Header>
          <h4>DRAWING - {row.part_no}     
             <span className ='btn btn-outline-danger btn-sm ml-3 rounded' onClick = { submitHandler }>
                 {loading ?(<Loader size='15' />):( <i className ='fa fa-refresh'></i>) }
              </span>
              <span className ='text-danger ml-3'>{err}</span>
          </h4>
          <CloseBtn onClick ={handleClose} />
        </Modal.Header>
        <Modal.Body className='dwg-body'>
           {/* {loading && (<Loader />)} */}
           {/* {data && data.length >0 && (<PreEcho obj = {data} />)} */}
           { file ? (<embed src ={`data:application/pdf;base64,${file}`} className='dwg-embed'/>)
                 : (<embed src ={`${row.file}`} className='dwg-embed'/>)
           }
           {/* {row?.file && (<embed src ={`${row.file}`} className='dwg-embed'/>)} */}
        </Modal.Body>
      </Modal>
    );
}


const Product = ()=>{

    const [showDrawing,setShowDrawing] = React.useState(false);
    const navigate = useNavigate();

    const [key,setKey] = React.useState('find');

    const ref = React.useRef(null);
    
    const {userInfo} = useSelector(state=>state.userLogin);

    React.useEffect(()=>{

        if(!localStorage.getItem('PID') && userInfo?.PID)
        {
           // console.log(userInfo);
           localStorage.setItem('PID',userInfo.PID);
        }

    },[userInfo]);
 
    let PID = userInfo?.PID 
                ? userInfo.PID 
                :localStorage.getItem('PID');

    if(PID === 'S01')
    {
        PID = 'S09';
    }

    const [business,setBusiness] = React.useState({
        type:'STAMP_DRAWING',
        material_type:'',
        part_no:'',
        description:'',
        from:PID,
        file:''
    })

    function convertToBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    }

    const handle = (e)=>{
        setShowDrawing(!showDrawing);
    }

    const changeHandler = async (e)=>{

        try {
              //  if(!e.target.value && e.target.name !== 'file') return;
         
         if(e.target.name !== 'file')
            {
            //    if(e.target.value === '#') return;
               setBusiness({
                   ...business,
                   [e.target.name]:e.target.value.toUpperCase()
                });
            } else {
               //it is file
               let file = e.target.files[0];

            //    console.log(file);
 
            //    if(!file) return;
           
               file = await convertToBase64(file);
            
               //save the base64 string
               setBusiness({
                   ...business,
                   [e.target.name] : file
               })

             
               setShowDrawing(true);
               

             
   
            }

        } catch (error){
            console.log(error.message);

        }

      
       
    };

   
    return (
        <>
           <div className ='row'>
               <span  
                  className = 'btn btn-light'
                  onClick = {()=>navigate('/admin')}
               ><i className='fa fa-backward mr-2'></i>返回</span>
           </div>
           {/* <div className ='row py-2'>
                <ItemList />
           </div> */}
           <div className = 'card mt-3' style = {{minHeight:'320px',fontWeight:400}}>
                <div className ='card-header text-center'>
                      <span className ='mx-auto text-danger'>产品管理 - F01</span>
                </div>

                <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className=""
                >
                    <Tab
                        eventKey= {`find`} 
                        title={<Translate k={`find`} />}
                    >
                        ...
                    </Tab>    
                    <Tab
                    eventKey= {`create`} 
                    title={<Translate k={`create`} />}
                    >
                        <div className = 'card-body p-3'>

                        <div className ='form-group'>
                            <label>material type<sup>*</sup></label>
                            <label className ='text-danger ml-2'>{business.material_type}</label>
        
                            <select 
                               name ='material_type' className ='form-control eparts-input'
                               value = {business.material_type}
                               onChange = {changeHandler}
                            >
                                <option >#</option>
                                <option >LOGISTIC</option>
                                <option >NUT</option>
                            </select>
                        </div>
                        <div className ='form-group'>
                            <label>part_no<sup>*</sup></label>
                            <input 
                            type = 'text' 
                            name = 'part_no'
                            className ='form-control eparts-input'
                            value = {business.part_no}
                            onChange = {changeHandler}
                            />
                            <span></span> 
                        </div>
                        <div className ='form-group'>
                            <label>description<sup>*</sup></label>
                            <input 
                            type = 'text' 
                            name = 'description'
                            className ='form-control eparts-input'
                            value = {business.description}
                            onChange = {changeHandler}
                            />
                            <span></span> 
                        </div>

                        <input type = 'file' name ='file' className ='d-none' ref = {ref} accept='application/pdf'  onChange = {changeHandler} />

                        <div className ='form-group mt-4'>
                            {
                                business?.part_no && business?.description && !['#',''].includes(business.material_type) &&(
                                    <span 
                                        className ='btn btn-outline-danger rounded btn-sm '
                                        // ref = {ref}
                                        onClick = {()=>ref.current.click()}
                                    >
                                        <i className ='fa fa-upload'>登记图纸</i>
                                    </span>
                                )
                            }
                        
                            <span 
                                className ={`btn ${business?.file.length > 100 ? 'btn-outline-danger':'btn-outline-secondary'} btn-sm rounded pull-right`}
                                onClick = {handle}
                            >
                                <i className ='fa fa-file-pdf-o'></i>
                                {business?.file && showDrawing  && (<ShowDrawing row = {business} handle = {handle} />)}
                            </span>
                    
                        </div>
                        </div>
                    </Tab>        
                </Tabs>
           </div>
           <pre className ='mt-3'>
                {/* {
                    JSON.stringify(
                        business,
                        null,
                        2
                    )
                } */}
           </pre> 
        </>
    );
};

export default Product;