import React from 'react';
import {useSelector} from 'react-redux';
// import nsk from '../images/NSK.png';
import ZJ from '../images/ZJ.png';
import Loader from './Loader';
// import { publish } from '../utils/socket';
import LanguageContext from '../contexts/contexts';

const ProductCard = ({product})=>{ 
     
     const {userInfo} = useSelector(state=>state.userLogin);

     const [loading,setLoading] = React.useState(false);

     const [scross,setScross] = React.useState({
        productID:product._id,
        brand_ref:product.refNumber,
        scode:userInfo?.PID,
        scross:''
     });

     const changeHandler = (e)=>{
        //   if(!e.target.value) return;

          setScross({
             ...scross,
             scross:e.target.value
          });
     }

     const submitHandler = (e)=>{
         if(window.confirm('确认提交:'+scross.scross+'?'))
         {
              setLoading(true);

              //publish as TODO_CONFIRM
              console.log('publish the scross',scross);

              setTimeout(()=>{
                setLoading(false);
              },3000);
         }
     }
    
    return (

        <div className ='card-body '>
            <div className ='eparts-row shadow p-3'>
                <div className ='hidden-xs col-md-8'>
                    {/* <span>{product.refNumber}</span> */}
                    <div className='p-5'>
                   
                        <div>
                            <ul>
                            {
                            product.dimension?.map((x,i)=>{
                                return  ( <li index = {i}>
                                    {
                                        Object.keys(x).map((item,i)=>{
                                            return (
                                                <span index = {i}>{item+ ':'+x[item]}</span>
                                            )
                                        })
                                    }
                                </li>)
                            })
                            }
                            </ul>
                
                        </div>
                        <img src = {product.imgUrl} width = '320'  alt ='photo'/>
                    </div> 
                </div>
                <div className='col-md-4'>
                <div className='form-group mr-5 mt-3 form-group-xs'>
                        <label className='text-danger'><b>{product.refNumber} / {scross.scross ? scross.scross:'#'}</b></label>
                        <div className ='input-group'>
                            <input 
                              type ='text' 
                              name ='scross'
                              className ='form-control eparts-input' 
                              placeholder = '对照号...'
                              value = {scross.scross}
                              onChange = {changeHandler}
                            />
                            <div className = 'input-group-append'>
                                <span 
                                  className ='btn btn-sm btn-outline-danger rounded'
                                  onClick = {submitHandler}
                                >
                                    {
                                    loading  ? (
                                        <Loader size ='18' />
                                    ):(<i className ='fa fa-save'></i>)
                                    }
                                </span>
                            </div>
                        </div>
                        <span className ='mt-4 text-danger'>对照号要跟图纸编号一致</span>
                    </div>
                </div>

            </div>
        </div>
    );

};
const Default = ()=>{

    const {product} = useSelector(state=>state.source);
    //loginLoading 
    const {loginLoading} = useSelector(state=>state.userLogin);

    const [loading,setLoading] = React.useState(loginLoading);

    React.useEffect(()=>{
       setTimeout(()=>{
           setLoading(false);

       },3000);

    },[]);

    const {language} = React.useContext(LanguageContext);
    return (
        <>
         
         { product?.length ? (<ProductCard product = {product[0]} />) :(


            <section>
                <div className = 'row text-center mt-4' >
                    <span className ='mx-auto'>

                        {loading ? (<Loader />) :(
                            <>
                                <img 
                                    src = {ZJ} 
                                    width ='80'
                                    alt = 'ZJ'  
                                />
                                <span className ='ml-3'>
                                    {language === 'en' ? 'ORDERING SERVICE' :'订货服务提供商'}
                                </span>
                            </>
                        )}
                       
                    </span>
                </div>
                <div 
                    style ={{
                        marginTop:'5vh',
                        minHeight:'150px',
                        backgroundImage:'url('+require('../images/pallet.jpg')+')',
                        backgroundPosition: 'center',
                        backgroundSize: '70%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                    ...
                </div>
          </section>
         )} 
        </>
     
    );
}

export default Default;