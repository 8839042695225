//main page to render the service
import { useState,useEffect } from 'react';
import {useSelector}  from 'react-redux';
import { useNavigate,useSearchParams} from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Translate from '../components/Translate';
import Admin from './Admin';
// import AutolineTable from '../tables/autoline/AutolineTable';

import Default from '../components/Default';
import TodoTable from '../tables/todo/TodoTable';


const HomeInput = ()=>{
     return (
        <div className = 'row mt-3' style = {{minHeight:'350px'}}>
            <div className ='hidden-xs col-md-4'>
                {/* <Token /> */}
            </div>
            <div className ='col-md-4 col-xs-8'>
                <div className ='form-group mx-auto  p-2'  style = {{maxWidth:'480px'}}>
                    <label className ='text-dark'>NSK或者其它业务编号<sup>*</sup></label>
                    <input 
                        type ='text' 
                        placeholder ='NSK ...' 
                        className ='form-control eparts-input mt-1'
                    
                    />
                </div>
            </div>
            <div className ='col-md-4 col-xs-4'>

            </div>
        </div>
     )
}

const Home = ()=>{
    const navigate = useNavigate();

    const {userInfo,PageOpenID} = useSelector(state=>state.userLogin);
    const {data} = useSelector(state=>state.todo);

    const [component,setComponent] = useState('default');

    useEffect(()=>{

        //if there are todo lines
        if(!data?.filter(x=>x.type !== 'forecast')?.length && ['IMPORTER'].includes(userInfo?.role_type)){
            navigate('/forecast');
        } 

        if(userInfo.role_type === 'STAFF')
        {
            localStorage.setItem('PID',userInfo.PID);
            navigate('/admin');
        }

        return ()=>{};

    },[userInfo]);

    const renderComponent = ()=>{
        switch (component)
        {
            default:
                return <Default />
        }
    }
    
    
    return (
        <>
            <TodoTable />
            {/* {renderComponent()} */}
        </>

    );
}

export default Home;