import React from "react";
import LanguageContext from "../contexts/contexts";

const Translate = ({k}) =>{
    const {language} = React.useContext(LanguageContext);
    let v = k;
    /*
       ['trackingNumber',
                  'appName',
                  'columnID',
                  'eventType',
                  'expireTime',
                  'status']
    */
    const keys = {
        find:{en:'SEARCH',cn:'查找'},
        create:{en:'CREATE',cn:'新增'},
        token : {en:'token',cn:'口令'},
        length:{en:'length',cn:'长'},
        width:{en:'width',cn:'宽'},
        height:{en:'height',cn:'高'},
        type:{en:'type',cn:'类型'},
        warehouseID:{en:'warehouse ID',cn:'仓库编号'},
        partNumber:{en:'Part Number',cn:'零件编号'},
        DRAWING:{en:'DRAWING',cn:'图纸'},
        sample:{en:'SAMPLE',cn:'样品'},
        CLAIM:{en:'CLAIM',cn:'索赔'},
        PHOTO:{en:'TAKE PHOTO',cn:'拍照'},
        RESEARCH:{en:'RESEARCH',cn:'品牌号'},
        brandRef:{en:'BRAND REF',cn:'品牌号'},
        amount :{en:'AMOUNT',cn:'金额'},
        out_trade_no :{en:'trade no',cn:'交易编号'},
        todo : {en:'TODO',cn:'待办'},
        todoItem:{en:'ITEM',cn:'物料编号'},
        step:{en:'STEP',cn:'工序'},
        price :{en:'pricing',cn:'定价'},
        source : {en:'source',cn:'上架'},
        BOM_DESIGN:{en:'BOM',cn:'清单'},
        XCROSS : {en:'cross',cn:'对照号'},
        itemNumber:{en:'item',cn:'型号'},
        publish:{en:'publish',cn:'发布'},
        oe :{en:'OE NUMBER',cn:'参考号'},
        dimension:{en:'DIMENSION',cn:'尺寸信息'},
        solution:{en:'type',cn:'类型'},
        base:{en:'PRIME',cn:'主选厂商'},
        apiType:{en:'TYPE',cn:'类型'},
        listType:{en:'TYPE',cn:'类型'},
        catalogID:{en:'CATALOG',cn:'目录'},
        home:{en:'HOME',cn:'主页'},
        admin:{en:'ADMIN',cn:'管理'},
        order:{en:'ORDER',cn:'订单'},
        photo:{en:'PHOTO',cn:'照片'},
        picture:{en:'PICTURE',cn:'照片'},
        refresh:{en:'REFRESH',cn:'刷新'},
        task:{en:'TASK',cn:'任务'},
        progress:{en:'PROGRESS',cn:'进度'},
        research:{en:'PRODUCT',cn:'产品管理'},
        payment : {en:'CASHIER',cn:'结账'},
        unknown:{en:'UNKNOWN',cn:'待确认零件'},
        brand:{en:'BRAND',cn:'品牌'},
        brand_ref:{en:'BRAND_REF',cn:'对照号'},
        oe_brand : {en:'OE BRAND',cn:'原厂品牌'},
        oe_brand_ref:{en:'OE REF',cn:'参考号'},
        oe_number : {en:'OE Number',cn:'原厂号'},
        page:{en:'Page',cn:'页'},
        ref:{en:'ref',cn:'对照号'},
        qty_to_buy:{en:'quantity',cn:'数量'},
        price_old:{en:'price',cn:'含税价'},
        baseRef:{en:'Base Ref',cn:'基准号码'},
        order_no : {en:'ORDER NO',cn:'订单号'},
        customerID:{en:'CUSTOMER ID',cn:'客户编号'},
        customer_order:{en:'CUST ORDER',cn:'客户单号'},
        delivery_date:{en:'DELIVERY DATE',cn:'行交期'},
        order_qty : {en:'ORDER QTY',cn:'订货数量'},
        pending_qty:{en:'PENDING QTY',cn:'未清数量'},
        purchase:{en:'PURCHASE',cn:'采购订单'},
        sales:{en:'SALES ORDER',cn:'销售订单'},
        query:{en:'INQUIRY',cn:'询价'},
        location:{en:'LOCATION',cn:'库位管理'},
        job:{en:'JOBS',cn:'日常工单'},
        production:{en:'PRODUCTION',cn:'生产管理'},
        tableName:{en:'table name',cn:'表名称'},
        comments:{en:'comments',cn:'备注'},
        cost :{en:'cost',cn:'单价'},
        appName:{en:'APP NAME',cn:'模块'},
        trackingNumber:{en:'tracking number',cn:'运单号'},
        columnID:{en:'columnID',cn:'字段名'},
        eventType:{en:'event type',cn:'类型'},
        expireTime:{en:'expire time',cn:'有效期'},
        item_code:{en:'ITEM CODE',cn:'物料编号'},
        ref_no :{en:'ref_no',cn:'对照编号'},
        price :{en:'price',cn:'价格'},
        note:{en:'note',cn:'备注'},
        part_no:{en:'part_no',cn:'零件编号'},
        scross:{en:'ref_no',cn:'对照号'},
        cross_ref:{en:'cross_ref',cn:'参照号码'},
        dwg:{en:'drawing',cn:'图纸'},
        min_qty:{en:'min_qty',cn:'最小库存量'},
        offer:{en:'offer',cn:'报价'},
        search:{en:'ref_no',cn:'参考号码'},
        cur:{en:'cur',cn:'货币'},
        status:{en:'status',cn:'状态'},
        action:{en:'ACTION',cn:'操作'},
        description:{en:'description',cn:'描述'},
        qty:{en:'qty',cn:'用量'},
        drawing:{en:'drawing',cn:'图纸'},
        target:{en:'target',cn:'目标价'},
        service_type:{en:'SERVICE_TYPE',cn:'服务类型'},
        forecast:{en:'forecast',cn:'预测'},
        ticket:{en:'TICKET',cn:'工单'}
    };

    try {
        v = keys[k][language];
    } catch(e){
        v = k; 
    }
  
    return (<>{v}</>)
} 
export default Translate;