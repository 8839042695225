import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';

const Absent = ()=>{

    const [err,setErr] = React.useState('');
    const [msg,setMsg] = React.useState('');
    const [loading,setLoading] = React.useState(false);

    const {userInfo} = useSelector(state=>state.userLogin);

    const navigate = useNavigate();

    const [business,setBusiness] = React.useState({
        date:'0000-00-00',
        timeSpan:'#',
        hourRate:30
    });

    const msgBox = (msg)=>{
        setErr(msg);
        setTimeout(()=>{
            setErr('');
        },5000);
    }

    const changeHandler = (e)=>{
          
        // if(e.target.name === 'date' && !/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/.test(e.target.value))
        // {
        //     return msgBox('日期格式0000-00-00');
        // }

        if(e.target.name === 'timeSpan' && !['上午','下午','全天'].includes(e.target.value))
        {
            return msgBox('时间区间有误');
        }

        setBusiness({
            ...business,
            [e.target.name]:e.target.value
        });

    };

    const submitHandler = async (e)=>{
        try {
            // e.stopPropagation();
            
            if(!/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/.test(business.date))
            {
                return msgBox('日期格式0000-00-00');
            }

            setLoading(true);
            
            //http_request
            const config = {
              headers : {
               'Content-Type':'application/json'
               // Authorization: `Bearer ${ userInfo.token }`
              }
            };

            //EDIT => update the record
            const URL =process.env.NODE_ENV === 'production'
            ?'https://service.eparts.cn/api/collaborates'
            :'http://localhost:4000/api/collaborates'; 
            
            let absent_type = 'DAY';
            let cost = 0;

            switch(business.timeSpan)
            {
                 case '上午':
                    absent_type = 'MORNING';
                    cost = 4 * business.hourRate;
                    break;
                 case '下午':
                    absent_type = 'AFTERNOON';
                    cost = 4 * business.hourRate;
                    break;
                 case '全天':
                    absent_type = 'DAY';
                    cost = 8 * business.hourRate;
                    break;
                 default:
            }

            if(!cost)
            {
                throw new Error('没有金额');
            }

            const {data:{msg}}  = await axios.post(URL,{
                type:'ABSENT',
                absent_date:business.date,
                absent_type,
                cost,
                from:userInfo?.open_id

            },config);
            // console.log('response',data);
            setLoading(false);

            if(/ERROR/.test(msg)){
               throw new Error(msg);
            }

            setMsg(msg.trim());

         } catch (error){

            setErr(error.message.trim());
            setTimeout(()=>{
                setErr('');
            },5000);
            // console.log(error.message);
         }

    };

    return (
        <>
           <div className ='row'>
               <span  
                  className = 'btn btn-light'
                  onClick = {()=>navigate('/admin')}
               ><i className='fa fa-backward mr-2'></i>返回</span>
           </div>
           <div className ='card'>
                <div className ='card-header text-center'>
                   <span className ='mx-auto text-danger'>请假管理 - F02</span>
                </div>
                <div className ='card-body p-6'>
                   <div className ='form-group'>
                       <label>请假时间:<sup>*</sup><span className ='ml-3 text-danger'><b>请假费用 {business.hourRate}元/小时</b></span></label>
                       <div className ='input-group'>
                           <input 
                                type ='text' 
                                name='date' 
                                className ='form-control eparts-input'
                                placeholder = '0000-00-00'
                                value  = {business.date}
                                onChange = {changeHandler}
                                
                           />
                           <select  
                              name='timeSpan' 
                              className ='form-control eparts-input' 
                              value =  {business.timeSpan}
                              onChange = {changeHandler}
                           >
                                <option>#</option>
                                <option>上午</option>
                                <option>下午</option>
                                <option>全天</option>
                           </select>

                           <div className ='input-group-append'>
                               <span 
                                  className ='btn btn-sm btn-outline-danger rounded'
                                  onClick = {submitHandler}
                               >
                                   {loading ?(<Loader size = '15' />):(<i className ='fa fa-paper-plane'></i>)}
                               </span>
                           </div>
                       </div>
                       <span className ='text-dark mt-4'>{msg}</span>
                       <span className ='text-danger mt-4'>{err}</span>
                   </div>

                </div>
           </div>
        </>
    );
};

export default Absent;