import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';

// import { pdfjs } from 'react-pdf';

// import 'core-js/features/array/at';
import {pdfjs, Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import CloseBtn from '../../components/CloseBtn';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';

// import './sample.css';

// Object.defineProperty(Array.prototype, "at", {
//     value: function(i) {
//         if (i < 0)
//             i += this.length
//         return this[i]
//     },
//     enumerable: false
// });


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

// pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

export default function RenderPdf() {
  
  const [show,setShow] = React.useState(false);
  const [file, setFile] = useState('./sample.pdf');
  const [numPages, setNumPages] = useState(null);
  
  function onFileChange(event) {
      setFile(event.target.files[0]);
      setShow(true);
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const handleClose = ()=>setShow(!show);

  return (
    <> 
         <div className="Example__container__load">
            <label htmlFor="file">Load from file:</label>{' '}
            <input onChange={onFileChange} type="file" />
             <span 
                className='btn btn-sm btn-outline-danger rounded'
                onClick = {()=>setShow(!show)}
             >
                 <i className ='fa fa-eye'></i>
             </span>
            </div>
            <Modal show={show} onHide={handleClose} className='modal-template'>
                <Modal.Header>
                   <CloseBtn onClick ={handleClose} />
                </Modal.Header>
                <Modal.Body className='dwg-body'>
                    <div className="Example">
                        <div className="Example__container">
                            <div className="Example__container__document">
                            <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                                {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
    </>


  );
}