// This must be the first line in src/index.js
// import 'react-app-polyfill/stable';
// import 'core-js/features/array/at';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './normalize.css';
import './bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css'; 

// import "core-js";

//language
import LanguageProvider from './contexts/LanguageProvider';

//redux
import { Provider }  from 'react-redux';
import store from './redux/store';
import App from './App';
export * from "core-js/es/array/at";
const root = ReactDOM.createRoot(document.getElementById('appRoot'));

root.render(
  <Provider store = {store}>
      <LanguageProvider>
          <App />
      </LanguageProvider>
  </Provider>
);

