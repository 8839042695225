import { legacy_createStore,combineReducers,applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import socketMiddleware from './middleware/socketMiddleware';

import {userReducer} from './reducers/userReducer';
import {paymentReducer} from './reducers/paymentReducer';
import { forecastReducer } from './reducers/forecastReducer';
import { messageReducer } from './reducers/messageReducer';
import { researchReducer } from './reducers/researchReducer';
import taskReducer from './reducers/taskReducer';
import sourceReducer from './reducers/sourceReducer';
import toBuyReducer from './reducers/toBuyReducer';
import todoReducer from './reducers/todoReducer';
import inputReducer from './reducers/inputReducer';
import autolineReducer from './reducers/autolineReducer';
import wepayReducer from './reducers/wepayReducer';
import productReducer from './reducers/productReducer';
import uploadReducer from './reducers/uploadReducer';
import actionReducer from './reducers/actionReducer';

//the reducers
const reducer = combineReducers({
     userLogin:userReducer,
     message:messageReducer,
     action:actionReducer,
     payment:paymentReducer,
     research:researchReducer,
     task: taskReducer,
     product:productReducer,

     todo :todoReducer,
     toBuy:toBuyReducer,
     forecast:forecastReducer,
     source:sourceReducer,
     input:inputReducer,
     autoline:autolineReducer,
     wepay:wepayReducer,
     upload:uploadReducer,
   
});

const initialState = {
    userLogin : {PageOpenID:'#',socketID:'#',token:'#',userInfo:{}}
};

const middleware = [thunk,socketMiddleware];
const store = legacy_createStore(reducer,initialState,composeWithDevTools(applyMiddleware(...middleware)));

export default store;