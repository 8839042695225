


const Subscribe = ()=>{
    
    return (
        <p>render the job which is  checking </p>
    )
}

export default Subscribe;