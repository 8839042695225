import { ACTION_FETCH } from "../constants/actionConstants";

const actionReducer = (state={data:[]},action) =>{
    switch (action.type)
    {  
        case ACTION_FETCH:    
           return {...state,data:action.payload.action};
        
        default:
           return state;
    }
};

export default actionReducer;
