import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { publish } from '../utils/socket';
import Loader from '../components/Loader';
import Actions from '../business/Actions';
import Message from '../components/Message';

//subscribe page
const Subscribe = ()=>{

    const {ref} = useParams();
    const [loading,setLoading] = React.useState(false);
    const {userInfo}           = useSelector(state=>state.userLogin);


    React.useEffect(()=>{

        try {
            
            setLoading(true);

            const openID = ref.split('::')[0];
            
            publish({
                type:'TASK_LOGIN',
                openID
            });
    
            setTimeout(()=>{
                setLoading(false);
            },3000);
    

        } catch (error){}
  
    },[]);

    return (
        <>
           {loading  ? (
              <Loader />
           ):(
               <>
                {
                    userInfo?.PID ? (
                        <Actions inputRef = {ref} />
                    ) : (
                        <Message>{`网络错误NETWORK ERROR`}</Message>
                    )
                }
                </>
          )}
            
     
        </>
    );
}

export default Subscribe;
