import LanguageContext from "../contexts/contexts";
import {useContext} from 'react';
import {useSelector} from 'react-redux';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import { publish } from '../utils/socket';

const LanguageBtn = ()=>{
   const {language,setLanguage} = useContext(LanguageContext);

   const {token} = useSelector(state=>state.userLogin);
   
   

   const {userInfo} = useSelector(state=>state.userLogin);
 
   const handleMode=()=>{
      setLanguage(language ==='en'?'cn':'en');
   }

   const debugLogin = (e)=>{

       if(process.env.NODE_ENV === 'production') return;
       //go with the token
       publish({
           type:'DEBUG_LOGIN',
           openID:'oV9Cut8y8vzP3DzlLZWIJ8RNfdn8',
           token
       })

       //publish the login as Li
      //  alert('login...');
       
   }
   return (
    <span>
       <BootstrapSwitchButton 
       onlabel = {'EN'}  
       offlabel={'中文'} 
       checked={language === 'en' ? false:true} 
       onstyle="outline-danger"  
       offstyle="outline-danger" 
       width={80} 
       height={24} 
       onChange={handleMode}
       />
       {
         !userInfo?.PID 
          && !/subscribe/.test(window.location.href) 
          &&
          ( <span 
               className ='ml-2 btn btn-sm btn-outline-danger rounded'
               onClick = {debugLogin}
            >
             <b>ID:{token}</b>
            </span>
         )
      }
      
    </span>
   
   );
   
}
export default LanguageBtn;